import * as Sentry from "@sentry/sveltekit";
import { handleErrorWithSentry } from "@sentry/sveltekit";
import type { HandleClientError } from "@sveltejs/kit";

import { PUBLIC_SENTRY_DSN } from "$env/static/public";

Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	integrations: [new Sentry.Replay()],

	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

// This will catch errors in `load` functions from `+page.ts` files
export const errorHandler = (({ error, event }) => {
	// eslint-disable-next-line no-console
	console.error("An error occurred on the client side:", error, event);

	return {
		// @ts-expect-error error is unknown
		message: error.message,
	};
}) satisfies HandleClientError;

export const handleError = handleErrorWithSentry(errorHandler);
